<template>
  <div class="container-fluid">
    <b-overlay :show="testLoading">
    <b-card v-if="quizTotal">
      <b-card-body>
        <QuizPreview :quiz="quizzes[(quizNo - 1)]" :graded="true" :key="`quiz_key_${quizNo}`" v-if="test.graded" />
        <QuizPreview v-model="userAnswers[(quizNo - 1)]" :quiz="quizzes[(quizNo - 1)]" :preview="timeout" :graded="test.graded" :key="`quiz_key_${quizNo}`" v-else />
      </b-card-body>
      <template v-slot:header>
        <div class="row align-items-baseline px-1">
          <span>
            <span>Question: </span>
            <span class="lead font-weight-bold">{{quizNo}}</span>
            <span> / {{quizTotal}}</span>
          </span>
          <span class="ml-auto" v-if="!test.graded">
            <span class="text-danger mr-3" v-if="timeout">Timeout!</span>
            <CountDownTimer :class="{'text-danger': warnUser}" v-model="remainder" @timeout="handleTimeout()" />
          </span>
        </div>
      </template>
      <template v-slot:footer>
        <QuizPagination v-model="quizNo" :quizArray="quizzes" :graded="test.graded" />
        <div class="row justify-content-end mt-3">
          <button class="btn btn-sm btn-outline-primary" @click="$router.push({name:'test-result', params: {book_id, test_id}})" title="See the test result" v-if="test.graded">
            Test Result
          </button>
          <button class="btn btn-sm btn-outline-primary" @click="submitTest" title="Finish the test" :disabled="timeout" v-else>
            <b-spinner small v-if="submitting" />
            Finish
          </button>
        </div>
      </template>
    </b-card>
    </b-overlay>
  </div>
</template>

<script>
import getQuizMixin from '@/mixins/getquiz';
import QuizPreview from '@/views/protected/backend/quiz/components/QuizPreview.vue';
import settings from '@/app.config';
import CountDownTimer from '../components/CountDownTimer.vue';
import QuizPagination from '../components/QuizPagination.vue';

export default {
  name: 'TestQuestion',
  mixins: [getQuizMixin],
  components: { QuizPreview, CountDownTimer, QuizPagination },

  data() {
    return {
      testLoading: false,
      submitting: false,
      timeout: false,
      test_id: this.$route.params.test_id,
      book_id: this.$route.params.book_id,
      quizNo: +(this.$route.params.question_no || 1),
      quizTotal: 0,
      remainder: 0,
      test: null,
      quizzes: [],
      userAnswers: [],
    };
  },

  computed: {
    warnUser() {
      return this.remainder <= settings.testWarnUserTimer;
    },
  },

  methods: {
    getTest() {
      if (this.book_id && this.test_id) {
        this.testLoading = true;
        this.$store.dispatch('test/getTestById', this.test_id).then((test) => {
          this.test = Object.assign({}, test);
          this.quizTotal = test.quiz_count;
          this.quizzes = [...test.quizzes];

          // initialize user answers array if this test is not yet graded
          if (!test.graded) {
            this.userAnswers = [];
            this.quizzes.forEach(() => { this.userAnswers.push([]); });
          }

          // evaluate remaining time and timeout
          const elapsedSeconds = Math.floor((Date.now() - (new Date(test.created_on))) / 1000);
          const remainingSeconds = test.time_allowed - elapsedSeconds;
          if (test.graded || remainingSeconds < 0) this.timeout = true;
          else this.remainder = remainingSeconds;
        }).catch(() => {
          this.$toast.error('Failed to start the test. Please, try again later.');
        }).finally(() => {
          this.testLoading = false;
        });
      }
    },
    handleTimeout() {
      this.timeout = true;
      this.$toast.error('No more time left. The test will now be submitted.');
      setTimeout(() => { this.submitTest(); }, 5E3); // 5 seconds
    },
    submitTest() {
      const timeSpent = +(this.test.time_allowed - this.remainder);
      const answers = [];
      let completed = true;

      for (let i = 0; i < this.quizTotal; i++) {
        const userAnswer = this.userAnswers[i] || [];
        if (userAnswer.length === 0) completed = false;
        answers.push({ id: this.quizzes[i].id, user_answers: userAnswer });
      }

      const gradeDetails = {
        id: this.test_id, answers, time_spent: timeSpent, completed,
      };
      this.submitting = true;
      this.$store.dispatch('test/gradeTest', gradeDetails).then(() => {
        this.$router.push({ name: 'test-result', params: { book_id: this.book_id, test_id: this.test_id } });
      }).catch(() => {
        this.$toast.error('Failed to submit the test. Please, try again later.');
      }).finally(() => {
        this.submitting = false;
      });
    },
  },

  created() {
    this.getTest();
  },
};
</script>
